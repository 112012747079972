.GenericTemplateFieldsList {
  padding: 15px;
  box-sizing: border-box;
}

.GenericTemplateFieldsList h2 {
  color: #555;
  margin: 0;
}

.GenericTemplateFieldsList .none {
  text-align: center;
}

.GenericTemplateFieldsList .template-info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 15px;
}

.GenericTemplateFieldsList .template-info .save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.GenericTemplateFieldsList .field-wrapper {
  margin-bottom: 7.5px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 5;
  align-items: center;
}

.GenericTemplateFieldsList .field-wrapper svg {
  cursor: pointer;
  height: 10px;
  opacity: 0.5;
}

.GenericTemplateFieldsList .field-wrapper svg:hover {
  opacity: 1;
}

.GenericTemplateFieldsList .fields-wrapper .type {
  grid-column: 1 / -1;
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: 700;
}

.GenericTemplateFieldsList .fields-wrapper {
  margin-bottom: 30px;
}

.GenericTemplateFieldsList .field-to-add-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.GenericTemplateFieldsList .field-to-add-wrapper > div,
.GenericTemplateFieldsList .field-to-add-wrapper > button {
  margin-right: 15px;
}

.GenericTemplateFieldsList .field-to-add-wrapper > svg:hover {
  cursor: pointer;
}

.GenericTemplateFieldsList .fields-to-add > .explain {
  font-weight: 300;
  margin-top: 15px;
}

.GenericTemplateFieldsList .fields-to-add > button,
.GenericTemplateFieldsList .fields-to-add > .explain {
  width: 100%;
  text-align: center;
}
