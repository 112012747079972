.TemplatesDashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 15px;
  box-sizing: border-box;
}

@media all and (max-width: 800px) {
  .TemplatesDashboard {
    grid-template-columns: 1fr;
  }

  .TemplatesDashboard h2 {
    font-size: 1em;
  }
}

.TemplatesDashboard h2 {
  grid-column: 1 / -1;
  text-align: center;
  color: #555;
}

.TemplatesDashboard > .loader {
  grid-column: 1 / -1;
  justify-self: center;
  align-self: center;
}

.TemplatesDashboard > .UnassociatedTemplatesList,
.TemplatesDashboard > .AllTemplatesList {
  min-height: 400px;
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #888;
}

.TemplatesDashboard > .UnassociatedTemplatesList > header,
.TemplatesDashboard > .AllTemplatesList > header {
  padding: 15px;
  background-color: #EEE;
  border-bottom: 1px dotted #999;
  font-weight: bold;
}

.TemplatesDashboard > .UnassociatedTemplatesList a,
.TemplatesDashboard > .AllTemplatesList a {
  display: block;
  padding: 15px;
  color: grey;
  border-bottom: 1px dotted grey;
  text-decoration: none;
}

.TemplatesDashboard > .UnassociatedTemplatesList .org-name,
.TemplatesDashboard > .AllTemplatesList .org-name {
  text-transform: uppercase;
  display: block;
  font-size: .7em;
  font-weight: bold;
}

.TemplatesDashboard > .UnassociatedTemplatesList a:hover,
.TemplatesDashboard > .AllTemplatesList a:hover {
  border-color: #7D4CDB;
  color: #7D4CDB;
  font-weight: bold;
}
