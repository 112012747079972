.TemplateMappingPanel {
  padding: 15px;
  box-sizing: border-box;
  border-right: 1px solid #555;
}

.TemplateMappingPanel h2, p {
  margin: 0;
}

.TemplateMappingPanel h2 {
  color: #555;
  margin-bottom: 15px;
}

.TemplateMappingPanel label {
  font-weight: bold;
  color: #555;
  font-size: .8em;
}

.TemplateMappingPanel .template-info-wrapper {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.TemplateMappingPanel .generic-and-save {
  display: flex;
  flex-direction: column;
}

.TemplateMappingPanel .save {
  align-self: flex-end;
}

.TemplateMappingPanel .section-wrapper {
  padding-left: 5px;
}

.TemplateMappingPanel .section-name {
  font-weight: bold;
  color: #555;
  border-bottom: 1px solid #DDD;
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 33%;
}

.TemplateMappingPanel .field-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 10px;
}

.TemplateMappingPanel .field-wrapper > p {
  margin-bottom: 5px;
}

.TemplateMappingPanel .field-name {
  font-weight: bold;
}

.TemplateMappingPanel .field-wrapper .icon {
  justify-self: center;
}
