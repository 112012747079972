.LoginView {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  min-width: 300px;
  width: 50%;
  margin: auto;
  padding: 30px;
  box-sizing: border-box;
}

.LoginView img {
  height: auto;
  width: 80%;
  margin: auto;
  margin-bottom: 30px;
}

.LoginView label {
  font-weight: bold;
  color: #555;
  font-size: .8em;
}

.LoginView form > div {
  margin-bottom: 15px;
}
