.TemplateMappingView {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.TemplateMappingView .loader {
  grid-column: 1 / -1;
  align-self: center;
  justify-self: center;
}
