.AllTemplatesList .search-bar {
  display: flex;
  align-items: center;
  border-bottom: 1px dotted #555;
  padding: 0 15px;
  box-sizing: border-box;
}

.AllTemplatesList .loader {
  margin: auto;
  margin-top: 30px;
}
