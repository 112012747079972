.Authenticated nav {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: aliceblue;
  border-bottom: 1px solid grey;
}

.Authenticated nav span {
  font-weight: bold;
}
